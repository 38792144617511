import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAuthentication, PrivateRoute, getContext } from '@onemedical/auth';
import MicroFrontend from '@onemedical/micro-frontend';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import AppBar from './components/AppBar';
import Notice from './components/Notice';
import hostedMicroFrontends from './hostedMicroFrontends';
import HomePage from './pages/HomePage';
import LogoutPage from './pages/LogoutPage';
import MicroFrontendConfig from './types/MicroFrontendConfig';

const getProfile = gql`
  query GetProfile {
    profile {
      id
      displayName
      roles {
        id
      }
    }
  }
`;

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
});

function assetsUrlFor(hostedMicroFrontend: MicroFrontendConfig) {
  const assetsUrl = new URL(window.location.origin);
  if (process.env.NODE_ENV === 'development') {
    assetsUrl.port = hostedMicroFrontend.localPort.toString();
  } else {
    assetsUrl.pathname = hostedMicroFrontend.path;
  }
  return assetsUrl.toString();
}

function App() {
  const { authenticating, authenticated, error, getToken } = useAuthentication();
  const { data, loading } = useQuery(getProfile, { skip: !authenticated });

  const authorizedMicroFrontends = data
    ? hostedMicroFrontends.filter((mf) =>
        mf.authorizedRoles.some((r) =>
          data.profile.roles.map((role: { id: number }) => role.id).includes(r),
        ),
      )
    : [];

  // To be removed once all hosted micro-frontends have been upgraded to @onemedical/auth v3
  useEffect(() => {
    const storeToken = async () => {
      const token = authenticated ? await getToken() : '';
      window.localStorage.setItem('auth:token', token);
    };
    storeToken();
  }, [getToken, authenticated]);

  const authContext = getContext();

  return authenticating || error ? (
    <Notice mt={20}>{error || 'Checking credentials . . .'}</Notice>
  ) : (
    <>
      <Route path="/">
        <AppBar userName={data && data.profile.displayName} />
      </Route>
      <main>
        <Switch>
          <Route path="/auth/logout" component={LogoutPage} />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <PrivateRoute path="/home" exact>
            <HomePage microFrontends={authorizedMicroFrontends} loading={loading} />
          </PrivateRoute>
          {hostedMicroFrontends.map((mf: MicroFrontendConfig) => (
            <PrivateRoute
              key={mf.id}
              path={mf.path}
              render={({ history }) =>
                authorizedMicroFrontends.find((am) => mf.id === am.id) ? (
                  <MicroFrontend
                    id={mf.id}
                    host={assetsUrlFor(mf)}
                    history={history}
                    containedAppProps={{ authContext }}
                    onError={(err) => {
                      console.error(`Failed to load '${mf.id}' app`, err); // eslint-disable-line no-console
                    }}
                  >
                    <Notice mt={20}>{error || `Loading  ${mf.description} . . .`}</Notice>
                    <>{Sentry.setTag('container_frontend', mf.id)}</>
                  </MicroFrontend>
                ) : (
                  !loading && <Notice mt={20}>You&apos;re not authorized to see this app.</Notice>
                )
              }
            />
          ))}
          {authenticated && data && (
            <Route path="*">
              <Notice mt={20}>Page not found</Notice>
            </Route>
          )}
        </Switch>
      </main>
    </>
  );
}
export default App;
